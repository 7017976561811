<template>
  <div>
    <div class="product-detail">
        <a href="/">返回首页</a>
        <div class="title-header">
            <div class="header-img">
                <img src="../../components/images/rider.png">
            </div>
            <div class="header-info">
                <div class="software-name">同城CALL骑手版</div>
                <div class="software-company">恒臻科技（大连）有限责任公司</div>
            </div>
        </div>
        <div class="title-content">
            <div class="content-name">应用名称：</div>
            <div class="content-info">
                同城CALL骑手版
            </div>
            <div class="content-name" style="margin-top:30px">产品介绍</div>
            <div class="content-info">
                同城CALL骑手版是由恒臻科技（大连）有限责任公司编写的一款APP应用，本应用致力于真实、高效、免费的本地服务，为外卖骑手提供一个良好的服务平台。<br/>
                骑手在登录注册同城CALL骑手版APP后，可以看到发布的所有配送订单，根据自身情况选择接收订单，完成服务。并在服务结束后查看到用户对自身的评价信息。
            </div>

            <div class="content-name" style="margin-top:30px">应用详情：</div>
            <div class="content-info">
                同城CALL骑手版是一款专为骑手设计的高效接单与配送管理应用，旨在为骑手提供便捷、智能、安全的工作体验。通过与商家和消费者紧密连接，我们致力于优化配送流程，<br/>
                提升服务效率，确保每一次配送都能准时、准确、安全地完成。
            </div>
            <div class="content-name" style="margin-top:30px">服务信息：</div>
            <div class="content-info">
                **一键登录：**支持微信、苹果账号授权或手机号快速注册与登录，简化登录流程，保障信息安全。<br/>
                **订单管理：**实时接收外卖订单，查看订单详情，包括顾客信息、取货地址、送货地址及特殊要求，确保配送无误。<br/>
                **通讯便捷：**一键拨打顾客电话，及时沟通订单细节，提升顾客满意度。<br/>
            </div>
        </div>
        <div class="title-footer">
            <img src="../../components/images/m-screenshot/1.png">
            <img src="../../components/images/m-screenshot/2.png">
            <img src="../../components/images/m-screenshot/3.png">
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CityCallMechanic',
  components: {},
  data(){
    return{
    }
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style scoped>
    .product-detail {
        width: 70vw;
        margin: 0px 15vw;
        padding: 30px 60px;
        min-width: 400px;
        min-height: 400px;
        background-color: #F0F8FF;
    }

    .title-header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 200px;
    }

    .header-img {
        width: 120px;
        height: 120px;
    }

    .header-img img {
        width: 120px;
        height: 120px;
        border-radius: 20px;
    }

    .header-info {
        width: calc(100% - 240px);
        margin: 0px 0px 0px 20px;
    }

    .software-name {
        font-size: 24px;
        font-weight: bold;
    }

    .software-company {
        font-size: 16px;
        color: dimgray;
    }

    .title-content {
        width: calc(100% - 120px);
        padding: 10px 60px 50px 60px;
    }

    .content-name {
        font-size: 18px;
        font-weight: bold;
    }

    .content-info {
        margin: 10px 0px 0px 0px;
        font-size: 14px;
    }

    .title-footer {
        width: 100%;
        height: 260px;
        padding: 0px 60px;
    }

    .title-footer img {
        width: 135px;
        height: 240px;
        margin: 0px 20px;
        border: 1px dashed #999;
    }
</style>
